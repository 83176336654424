.highlightText {
  text-shadow: 0 0 80px rgb(192 219 255 / 48%), 0 0 32px rgb(65 120 255 / 24%);
}

.code {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: calc(100vw - 32px);
  padding: 12px 0 28px;
  background: #282c34;
  box-shadow: -1px 1px 15px 4px rgb(40 45 51);
}

.codeLine {
  margin-top: 12px;
  padding: 0 15px 0 30px;
  text-align: right;
}

.codeLineNumber {
  color: #707a84;
  line-height: 1.5;
}

.fieldContainer {
  width: calc(100vw - 46px);
  height: calc(100vw - 46px);
  border-radius: 15px;
}

.fieldBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  border-radius: 15px;
  background-image: url('/images/hero/back.png');
  box-shadow: -5px 5px 25px 3px rgb(71 111 47);
}

.codeTextarea {
  height: 24px;
  margin: 0;
  resize: none;
  background: #46505e;
  color: #fff;
  width: 80%;
  padding-left: 2px;
  font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

.codeTextarea:focus {
  outline: none;
}

.cssCode {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  color: #abb2bf;
}

.cssCode span {
  color: #98c379;
}

.hero::before {
  display: block;
  content: '';
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
  background-image: url('/images/hero/divider.svg');
  padding: 12%;
  position: absolute;
  bottom: 0;
}

.highlightVideo {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}

@screen sm {
  .fieldContainer {
    width: calc(100vw * 0.5);
    height: calc(100vw * 0.5);
  }

  .code {
    max-width: calc(100vw * 0.5);
  }
}

@screen lg {
  .fieldContainer {
    width: 400px;
    height: 400px;
  }

  .code {
    max-width: 350px;
  }
}

.gameImage {
  aspect-ratio: 1 / 1;
}
